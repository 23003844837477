b {
  color: #343a40;
  font-weight: bold;
}

h4 {
  color: rgb(26, 130, 184);
}

.container {
  margin-top: 25px;
}

.content {
  margin-top: 50px;
}

#footer {
  margin-bottom: 25px;
}

#copyright-footer {
  padding: 5px 5px 5px 5px;
}

#copyright-footer a {
  color: white;
}

#footer p {
  color: white;
  font-size: 10px;
  margin: 5px 5px 5px 5px;
}

a:hover {
  text-decoration-line: none;
}

.link {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/*----------------------------NAVBAR CSS----------------------------------*/
/*------------------------------------------------------------------------*/

.navbar {
  visibility: hidden;
  display: none;
  padding: 10px 15px;
}

.navbar-collapsed {
  position: relative;
  background-color: #343a40;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.nav-link {
  color: white;
}

#collapsed-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  z-index: 999;
  display: flex;
  justify-content: center;
}

#close-menu-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

#collapsed-menu > ul {
  margin: 0;
  list-style: none;
  padding: 0;
  text-align: center;
  width: 100%;
  margin-top: 50px;
}

.nav-header {
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #343a40;
  margin-top: 65px;
}

.nav-header > a {
  color: white;
}

#collapsed-menu > ul > li {
  font-size: 18px;
  margin: 25px 16px;
}

.nav-link {
  padding: 0px 0px 0px 0px !important;
  align-self: center;
  text-align: center;
}

.nav-item {
  display: flex;
  position: relative;
  height: 48px;
  font-size: 14px;
  align-items: center;
}

.nav-item:hover {
  cursor: pointer;
}

.nav-item:hover .dropdown-content {
  display: inline;
}

.dropdown-content {
  position: absolute;
  display: none;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px 2px 3px 0px rgba(230, 230, 230, 1);
  box-shadow: 0px 2px 3px 0px rgba(230, 230, 230, 1);
  background-color: white;
  list-style: none;
  width: auto;
  z-index: 1;
  top: 48px;
  left: 0;
  padding-left: 0px;
  font-size: 12px;
}

.dropdown-content:hover {
  display: inline;
}

.dropdown-content li {
  padding: 10px 10px 10px 10px;
}
.dropdown-content li:hover {
  background-color: #343a40;
  color: white;
  transition: background-color 0.5s;
  text-decoration: none;
}

@media only screen and (min-width: 1024px) {
  .navbar {
    visibility: visible;
    display: block;
    padding: 5px 15px;
  }

  .navbar-collapsed {
    visibility: hidden;
    display: none;
  }
}

/*----------------------------HOMEPAGE CSS----------------------------------*/
/*------------------------------------------------------------------------*/

#homepage h4 {
  font-size: 25px;
  color: rgb(26, 130, 184);
}

#image-scroll {
  position: relative;
  margin-bottom: 1.5rem;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

#image-scroll > span {
  position: absolute;
  top: 50%;
  font-size: 34px;
  font-weight: bold;
  color: white;
  opacity: 0.5;
}

#image-scroll > span:hover {
  cursor: pointer;
}

#image-scroll > .left {
  left: 5px;
  margin-top: -17px;
}

#image-scroll:hover > .right {
  animation: focus-right 0.5s forwards;
  -webkit-animation: focus-right 0.5s forwards;
}

#image-scroll:hover > .left {
  animation: focus-left 0.5s forwards;
  -webkit-animation: focus-left 0.5s forwards;
}

@keyframes focus-right {
  from {
    opacity: 0.5;
    right: 5px;
  }
  to {
    opacity: 1;
    right: 10px;
  }
}

@keyframes focus-left {
  from {
    opacity: 0.5;
    left: 5px;
  }
  to {
    opacity: 1;
    left: 10px;
  }
}

#image-scroll > .right {
  right: 5px;
  margin-top: -17px;
}

#footer .card {
  margin-top: 50px;
  margin-bottom: 50px;
  border: none;
  display: block !important;
}

#footer .card:hover {
  background-color: white !important;
  transition: background-color 0.5s;
}

#footer .card:hover .card-text {
  color: #343a40;
  font-weight: bold;
}

#footer .card-text {
  font-size: 10px;
  letter-spacing: 2px;
  margin: 3px 3px 3px 3px;
  text-align: center;
  color: white;
}

#classespage li {
  margin-top: 12px;
}

.form-img {
  opacity: 0.5;
}

.form-img:hover {
  opacity: 1;
}

#beautypage ul {
  /* columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2; */
}

#beautypage #other-services {
  margin-top: 10px;
}

.btn-alt {
  color: white;
  font-weight: bold;
}

.btn-alt:hover {
  color: grey;
  transition: color 0.5s;
}
